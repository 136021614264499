<template>
    <div>
        <modal class="new-modal-small" :name="modal_name" transition="nice-modal-fade" :delay="100" height="auto"
            :min-height="200" :min-width="400" :pivot-y="0.5" :adaptive="true" :scrollable="true">
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center in-progress">
                <span class="text-secondary fw-600 text-center fs-16 m-15px">{{message}}</span>
            </div>
        </modal>
    </div>
</template>
<script>
    export default {
        props: ["modal_name", "message"],
    }
</script>
<style scoped>
    .in-progress{
        background-color: #dfe8f4 !important; height: 10em !important;
    }
    .m-15px{
        margin: 15px;
    }
</style>